.LoginPage-container {
	display: flex;
	flex-direction: column;
	width: fit-content;
	margin: 60px auto 0 auto;
	padding: 30px 60px;
	border-radius: 22px;
	box-shadow: 0 15px 40px -5px rgba(120,120,120, .1);
	background-color: #fff;
	gap: 15px;
}

.LoginPage-form-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 25px;
}

.LoginPage-title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
}

.LoginPage-fields-container {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

@media screen and (max-width: 500px) {
	.LoginPage-container {
		background-color: #fff;
		padding: 25px 35px;
		border: none;
		box-shadow: none;
	}
}

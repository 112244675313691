.HeroSection-container {
	/* height: 100vh; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: linear-gradient(20deg, #fff, 40%, #fec5d9,55%, #fc6c9f, 65%, #7198e5, #bc8df0);
	background-size: 400% 400%;
	padding: 130px 0 45px 0;
}

.HeroSection-partners-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 100px;
	gap: 20px;
}

.HeroSection-icons-container {
	display: flex;
	flex-direction: row;
	gap: 120px;
	align-items: center;
}

@media screen and (max-width: 768px) {
	.HeroSection-partners-container {
		margin-top: 80px;
	}

	.HeroSection-icons-container {
		gap: 25px;
	}

	.HeroSection-icons-container img {
		transform: scale(0.8);
	}
}

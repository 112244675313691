.LibraryFileList-container {
	max-width: 1275px;
	margin: 0 auto;
}

.LibraryFileList-header-buttons-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 15px;
	margin-bottom: 12px;
}

.LibraryFileList-upload-button {
	box-shadow: 0 12px 15px -12px rgba(0,0,0, .15) !important;
}

.LibraryFileList-upload-button:hover {
	box-shadow: 0 0 0 #fff !important;
}

.LibraryFileList-secondary-buttons-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 8px;
	margin-bottom: 10px;
}

.LibraryFileList-filters-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	background-color: #fff;
	padding: 8px 15px;
	border-radius: 15px;
	gap: 20px;
}

.LibraryFileList-filter-by-file-type-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;
}

.LibraryFileList-filter-by-file-type-btns-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 7px;
}

.LibraryFileList-filter-by-file-type-item {
	/* background-color: #eee !important; */
	background: linear-gradient(0deg, #ccc, 2%, #fafafa) !important;
	border-radius: 10px !important;
	padding: 5px !important;
}

.LibraryFileList-list {
	display: grid;
	justify-content: center;
	grid-template-columns: repeat(auto-fill, 238px);
	gap: 3px;
}

.LibraryFileList-list-item {
	position: relative !important;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 5px;
	justify-content: space-between;
	overflow: hidden;
	transition: .2s ease;
	cursor: pointer;
}

.LibraryFileList-list-item-checked {
	box-shadow: 0 7px 20px rgba(93, 135, 128, .6);
}

.LibraryFileList-list-item .LibraryFileList-list-item-select-button {
	position: absolute !important;
	display: none !important;
	justify-content: center;
	align-items: center;
	top: 5px !important;
	left: 5px !important;
	width: 28px;
	height: 28px;
	background-color: rgba(0,0,0, .7);
	backdrop-filter: blur(5px) saturate(180%);
	-webkit-backdrop-filter: blur(5px) saturate(180%);
	z-index: 1000 !important;
}

.LibraryFileList-list-item .LibraryFileList-list-item-filetype-icon-container {
	display: flex;
	padding: 4px;
	border-radius: 50%;
	border-radius: 10px;
	/* z-index: 1000 !important; */
}

.LibraryFileList-list-item .LibraryFileList-list-item-filetype-icon-container img {
	width: 22px;
	height: 22px;
}

.LibraryFileList-modal-linked-events-container {
	display: flex;
	flex-direction: column;
	max-height: 95px;
	overflow-y: scroll;
	padding: 8px;
	background-color: #eee;
	border-radius: 12px;
	gap: 5px;
}

.LibraryFileList-modal-linked-event-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}

.LibraryFileList-modal-linked-event-item .LibraryFileList-modal-linked-event-item-account-picture {
	width: 25px;
	height: 25px;
	border-radius: 50%;
}

.LibraryFileList-modal-linked-event-item .LibraryFileList-modal-linked-event-item-platform-icon {
	width: 18px;
	height: 18px;
	border-radius: 50%;
}

.LibraryFileList-list-item .LibraryFileList-list-item-select-button.LibraryFileList-list-item-select-button-visible {
	display: flex !important;
}

.LibraryFileList-list-item-view-icon {
	display: none !important;
	cursor: pointer;
	position: absolute !important;
	font-size: 22px !important;
	padding: 10px;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	border: 1px solid #333;
	border-radius: 12px;
	box-shadow: 0 12px 20px rgba(0,0,0, .3);
	background-color: rgba(0,0,0, .9);
	z-index: 1000 !important;
}

.LibraryFileList-list-item:hover .LibraryFileList-list-item-view-icon {
	display: block !important;
}

.LibraryFileList-list-item:hover .LibraryFileList-list-item-view-icon.LibraryFileList-list-item-view-icon-hidden {
	display: none !important;
}

.LibraryFileList-list-item .LibraryFileList-list-item-img-container {
	align-items: center;
	width: 238px;
	height: 238px;
	transition: .1s ease;
}

/* enable blur on hover on desktop only */
@media screen and (min-width: 1024px) {
	.LibraryFileList-list-item:hover .LibraryFileList-list-item-img-container {
		-webkit-filter: blur(2px);
		-ms-filter: blur(2px);
		filter: blur(2px);
	}
}

.LibraryFileList-list-item-actions-container {
	display: flex;
	flex-direction: row;
}

.LibraryFileList-modal-container {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	gap: 15px;
}

.LibraryFileList-modal-item.LibraryFileList-modal-img-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	gap: 12px;
}

.LibraryFileList-modal-item.LibraryFileList-modal-img-container .LibraryFileList-modal-img {
	display: block;
	height: calc(80vh - 100px);
	min-height: 500px;
	width: 100%;
	border-radius: 6px;
	background-color: #000;
}

.LibraryFileList-modal-item.LibraryFileList-modal-infos-container {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 300px;
	max-width: 450px;
	max-height: 600px;
	overflow-y: auto;
	gap: 15px;
	padding: 25px 20px;
	border-radius: 16px;
	box-shadow: 50px 60px 50px rgba(0,0,0, .25);
	background-color: rgb(248,248,248);
}

.LibraryFileList-modal-infos-item {
	display: flex;
	flex-direction: column;
}

.LibraryFileList-modal-metadata-container {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.LibraryFileList-modal-platforms-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 18px;
}

.LibraryFileList-modal-platform-item {
	width: 30px;
	border-radius: 12px;
	padding: 8px;
	box-shadow: 0 5px 10px rgba(0,0,0, .1);
}

.LibraryFileList-modal-infos-island {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 15px;
	padding: 8px 18px;
	background-color: #222;
	border-radius: 14px;
	box-shadow: 0 12px 20px -12px rgba(0,0,0, .7);
}

.LibraryFileList-empty-list {
	width: 100%;
	flex-direction: row;
	padding: 12px 15px;
}

.LibraryFileList-empty-list-animated-icon {
	animation: LibraryFileList-empty-list-animated-icon 1s infinite;
}


@media screen and (max-width: 768px) {
	.LibraryFileList-list {
		grid-template-columns: repeat(auto-fill, 180px);
	}

	.LibraryFileList-list-item .LibraryFileList-list-item-img-container {
		width: 180px;
		height: 180px;
	}

	.LibraryFileList-modal-container {
		flex-direction: column;
	}

	.LibraryFileList-modal-item.LibraryFileList-modal-infos-container {
		width: 90%;
	}

	.LibraryFileList-empty-list {
		flex-direction: column-reverse;
	}

	.LibraryFileList-secondary-buttons-container {
		margin-bottom: 25px;
	}

	.LibraryFileList-filters-container {
		width: fit-content;
		float: right;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 15px;
		padding: 15px 15px;
		gap: 15px;
	}

	.LibraryFileList-filter-by-file-type-container {
		align-items: flex-start;
	}
}

@media screen and (max-width: 460px) {
	.LibraryFileList-list {
		grid-template-columns: repeat(auto-fill, 95px);
	}
	
	.LibraryFileList-list-item .LibraryFileList-list-item-img-container {
		width: 95px;
		height: 95px;
	}

	.LibraryFileList-list-item-view-icon {
		padding: 5px;
	}
}


@keyframes LibraryFileList-empty-list-animated-icon {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: scale(1.1) translate(0, -5px);
	}
	100% {
		transform: translate(0, 0);
	}
}

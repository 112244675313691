.InsightsPage-container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 1300px;
	margin: 0 auto;
}

.InsightsPage-metrics-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
}

.InsightsPage-account-kpis-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 18px;
	margin-top: 40px;
}

.InsightsPage-account-kpi-item-container {
	display: flex;
	min-width: 180px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 30px;
	border-radius: 22px;
	background-color: #fff;
	box-shadow: 0 2px 4px 0 rgba(0,0,0, .1);
}

.InsightsPage-account-kpi-item-title {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	font-size: 1.1rem !important;
	font-weight: 600 !important;
	color: #000 !important;
}

.InsightsPage-account-kpi-item-value {
	font-size: 1.8rem !important;
	font-weight: 700 !important;
	color: #444 !important;
}

.InsightsPage-views-graph-container {
	width: 100%;
	height: 200px;
}


@media screen and (max-width: 768px) {
	.InsightsPage-account-kpis-container {
		flex-direction: column;
		gap: 12px;
	}

	.InsightsPage-account-kpi-item-container {
		min-width: 180px;
		width: 100%;
		padding: 14px 18px;
	}
}

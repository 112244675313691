.IASection-container {
	background-color: black;
}

.IASection-content-container {
	justify-content: center;
	text-align: center;
	padding: 80px 25px 0 25px;
	max-width: 890px;
	margin: 0 auto;
}

.IASection-title-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 45px;
}

.IASection-video-container {
	height: 260px;
	width: 50%;
	overflow-y: hidden;
	justify-content: center;
	margin: 80px auto 0 auto;
}

.IASection-video {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: top;
}

.Modal-container {
	overflow: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 1200px;
	max-height: 100%;
	background-color: white;
	border-radius: 15px;
	box-shadow: 0 20px 35px 0 rgba(0,0,0, .15);
}

.Modal-container.Modal-container-fit-content {
	min-width: unset;
	width: fit-content !important;
}

.Modal-close-btn-container {
	position: absolute;
	top: 10px;
	right: 10px;
}

.Modal-close-btn {
	background-color: #333 !important;
	padding: 7px !important;
}

.Modal-close-btn:hover {
	background-color: #555 !important;
}

.Modal-close-btn:active {
	background-color: #ddd !important;
}

.Modal-close-btn-icon {
	color: #ccc !important;
	font-size: 15px !important;
}

.Modal-close-btn:hover .Modal-close-btn-icon {
	color: #fff !important;
}

.Modal-close-btn:active .Modal-close-btn-icon {
	color: #222 !important;
}

.Modal-children-container {
	padding: 50px 35px 25px 35px;
}

.Modal-children-container.Modal-children-container-no-padding {
	padding: 0;
	
}

@media screen and (max-width: 1200px) {
	.Modal-container,
	.Modal-container.Modal-container-fit-content {
		min-width: 100%;
		height: 100%;
		border-radius: 0;
	}
}

.Footer-container {
	padding: 40px;
	background-color: #eee;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.Footer-brand-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Footer-links-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.PriceSection-pricing-container {
	background: linear-gradient(180deg, rgb(0,8,54), rgb(99,141,221));
	box-shadow: 0 60px 42px -35px rgba(0,0,0, .2);
	margin: 30px auto 50px auto;
	padding: 35px 40px;
	border-radius: 22px;
}

.PriceSection-pricing-container .PriceSection-logo-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
}

.PriceSection-pricing-container .PriceSection-price-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 15%;
	margin-bottom: 25px;
}

.PriceSection-pricing-container .PriceSection-text-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	margin: 45px 0;
	gap: 15px;
}

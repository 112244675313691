.PlanificationCalendar-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.PlanificationCalendar-calendar-loading-box {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	background-color: rgba(255,255,255, .8);
	backdrop-filter: blur(8px) saturate(250%);
	-webkit-backdrop-filter: blur(8px) saturate(250%);
	z-index: 100;
}

.PlanificationCalendar-calendar-loading {
	position: absolute;
	top: calc(50% - 13px);
	left: calc(50% - 13px);
}

.PlanificationCalendar-calendar-container {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: rgb(221,221,224);
	background: linear-gradient(0deg, rgb(221,221,224) 20%, rgb(232,232,235) 100%);
	border-radius: 22px;
	border: 1px solid #ddd;
	overflow: hidden;
	box-shadow: 0 60px 30px -35px rgba(0,0,0, .15);
	padding: 5px 15px 15px 15px;
}

.PlanificationCalendar-calendar-body {
	position: relative;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(7, minmax(0, 1fr));
	grid-column-gap: 10px;
}

@media screen and (max-width: 1100px) {
	.PlanificationCalendar-calendar-body {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-column-gap: 25px;
		grid-row-gap: 25px;
	}
}

@media screen and (max-width: 768px) {
	.PlanificationCalendar-calendar-body {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-row-gap: 25px;
	}
}

.CalendarPage-container {
	width: 100%;
	max-width: 1700px;
	margin: 0 auto;
}

.CalendarPage-header-container {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.CalendarPage-calendar-header-buttons-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 25px;
	margin-bottom: 40px;
}

.CalendarPage-calendar-ai-generation-button {
	background: linear-gradient(0deg, #ddd, 4%, #fff);
	border-radius: 16px !important;
	padding: 9px 22px !important;
	border: 2px solid rgba(160,160,255, .8) !important;
	box-shadow: 0 12px 22px rgba(0,0,0, .1) !important;
}

.CalendarPage-calendar-ai-generation-button:hover {
	box-shadow: 0 0 0 #fff !important;
}

.CalendarPage-calendar-box-container {
	position: relative;
}

.CalendarPage-calendar {
	height: calc(100vh - 200px);
}

.CalendarPage-event-file-selector-modal {
	background-color: #ddd;
	background: linear-gradient(200deg, #fff, 75%, #cacdf6);
	box-shadow: -8px  8px 30px rgba(0,0,0, .3),
				 8px -8px 30px rgba(255,255,255, .3);
}

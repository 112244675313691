.MetricsSection-container {
	padding: 60px 0 10px 0;
	background: linear-gradient(130deg, #D8EAE4 0%, #FFF 20%, #FAFDFE 80%, #F6DFE9 90%, #F6E2D2 100%);
}

.MetricsSection-title-container {
	text-align: center;
	padding: 0 20px;
}

.MetricsSection-imgs-container {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	margin-top: 50px;
	overflow: hidden;
	padding-bottom: 50px;
}

.MetricsSection-imgs-container .MetricsSection-img {
	width: 180px;
	border: 3px solid rgb(120,120,120);
	border-radius: 22px;
	box-shadow: 0 55px 35px -20px rgba(0,0,0, .15);
}

.MetricsSection-imgs-container .MetricsSection-floating-text,
.MetricsSection-imgs-container .MetricsSection-pre-floating-text {
	text-align: center;
	justify-content: center;
	align-items: center;
	gap: 100px;
}

.MetricsSection-imgs-container .MetricsSection-floating-text {
	margin-top: 40px;
}

.MetricsSection-imgs-container .MetricsSection-pre-floating-text {
	margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
	.MetricsSection-container {
		padding: 30px 0 0 0;
	}

	.MetricsSection-imgs-container .MetricsSection-img {
		width: 180px;
	}
}

.PlanificationCalendar-event-container {
	position: relative;
	width: 100%;
	border-radius: 12px;
	background: #fff;
	cursor: pointer;
	margin: 0;
	transition: .15s ease-in-out;
}

.PlanificationCalendar-event-container:hover {
	/* background: #f2f2f2; */
	transform: scale(1.02);
}

.PlanificationCalendar-event-container.PlanificationCalendar-event-incomplete {
	animation: PlanificationCalendar-incomplete-animation 1.5s ease-out infinite;
}

.PlanificationCalendar-event-header {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 5px;
	padding: 5px;
	border-radius: 9px;
}

.PlanificationCalendar-event-header-icons {
	margin: 0;
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	justify-content: center;
	gap: 0.5rem;
}

.PlanificationCalendar-event-header-icon {
	width: 2.3rem !important;
	height: 2.3rem !important;
	border: 1px solid #444;
	box-shadow: 0 5px 8px -2px rgba(0,0,0, .4);
}

.PlanificationCalendar-event-header-icon > * {
	color: #030303;
}

.PlanificationCalendar-event-header-infos {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.PlanificationCalendar-event-header-date,
.PlanificationCalendar-event-header-username {
	color: #000 !important;
	color: #aaa !important;
	font-size: 10px !important;
	font-weight: 700 !important;
	/* background: linear-gradient(0deg, #e5e5e5, #f5f5f5); */
}

.PlanificationCalendar-event-header-date {
	color: #222 !important;
	color: #ddd !important;
	font-size: 17px !important;
	font-weight: 1000 !important;
}

@media screen and (min-width: 1350px) and (max-width: 1600px) {
	.PlanificationCalendar-event-header-icons {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		gap: 0.4rem;
	}

	.PlanificationCalendar-event-header-icon:nth-child(-n+1) {
		/* display: none; */
	}
}

@media screen and (min-width: 1050px) and (max-width: 1350px) {
	.PlanificationCalendar-event-header-icons {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 0.3rem;
	}

	.PlanificationCalendar-event-header-icon:nth-child(-n+2) {
		/* display: none; */
	}
}

@media screen and (min-width: 950px) and (max-width: 1050px ) {
	.PlanificationCalendar-event-header-icons {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}

	.PlanificationCalendar-event-header-icon:nth-child(-n+3) {
		/* display: none; */
	}
}

.PlanificationCalendar-event-visual {
	position: relative;
	width: auto;
	margin: 10px;
	max-height: 150px;
}

.PlanificationCalendar-event-visual > img,
.PlanificationCalendar-event-visual > video {
	margin: 0;
	position: relative;
	width: 100%;
	border-radius: 5px;
	object-fit: cover;
	max-height: 150px;
	box-shadow: 0 2px 8px rgba(0,0,0, .2);
}

.PlanificationCalendar-event-visual-multi {
	position: absolute;
	bottom: 0.5rem;
	right: 0.5rem;
	color: #ffffff;
}

.PlanificationCalendar-event-footer {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	justify-content: space-between;
	margin: 0 5px 5px 5px;
	background-color: rgba(0,0,0, .8);
	backdrop-filter: blur(5px) saturate(200%);
	-webkit-backdrop-filter: blur(5px) saturate(200%);
	border-radius: 10px;
	padding: 5px 8px;
	box-shadow: 0 4px 10px rgba(0,0,0, .3);
}

.PlanificationCalendar-event-footer-description {
	position: relative;
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis !important;
	font-size: 0.75rem !important;
	font-weight: 600 !important;
	color: #eee !important;
}

.PlanificationCalendar-event-footer-status {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18px;
	height: 18px;
	backdrop-filter: blur(5px) saturate(180%);
	-webkit-backdrop-filter: blur(5px) saturate(180%);
	color: #fff;
	padding: 2px;
	border-radius: 50%;
}

@keyframes PlanificationCalendar-incomplete-animation {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 105, 135, 0.7);
	}
	70% {
		box-shadow: 0 0 0 6px rgba(255, 105, 135, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(255, 105, 135, 0);
	}
}

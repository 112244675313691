.PrivateRoute-container {
	display: flex;
	flex-direction: column;
	width: fit-content;
	margin: 60px auto 0 auto;
	padding: 30px 60px;
	border-radius: 22px;
	box-shadow: 0 45px 40px -20px rgba(0,0,0, .1);
	background-color: #fff;
	gap: 15px;
}

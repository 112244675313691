.PlanificationCalendar-day-container {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
}

.PlanificationCalendar-day-container.PlanificationCalendar-day-container-current {
	background-color: #eee;
}

.PlanificationCalendar-day-header {
	vertical-align: text-bottom;
	padding: 0.5rem 1rem;
	padding: 8px 10px;
	padding-bottom: 0;
	display: flex;
	/* font-family: 'IBM Plex Mono', 'Roboto Mono', monospace; */
	justify-content: space-between;
}

.PlanificationCalendar-day-header-day {
	color: #777;
	font-size: 13px;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
}

.PlanificationCalendar-day-container.PlanificationCalendar-day-container-current .PlanificationCalendar-day-header-date,
.PlanificationCalendar-day-container.PlanificationCalendar-day-container-current .PlanificationCalendar-day-header-day {
	color: #0096FF;
}

.PlanificationCalendar-day-header-date {
	color: #333;
	font-size: 13px;
	font-weight: 700;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: capitalize;
}

.PlanificationCalendar-day-header > p {
	margin: 0;
	padding: 0;
}

.PlanificationCalendar-day-separator {
	width: auto;
	height: 2px;
	border-radius: 2px;
	margin: 10px 8px;
	background-color: #bebebe;
}

.PlanificationCalendar-day-body {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	max-height: calc(50vh);
	min-height: calc(50vh);
	position: relative;
	overflow-y: auto;
	display: flex;
	padding: 0.5rem;
}

.PlanificationCalendar-day-body::-webkit-scrollbar {
	display: none;
}

.PlanificationCalendar-day-body-event {
	background-color: rgba(0,0,0, 0.1);
	border-radius: 12px;
	border: 1px dashed rgba(0,0,0, .5);
	height: 50px;
	width: 100%;
}

.PlanificationCalendar-day-not-empty-add-container {
	display: flex;
	position: absolute;
	top: -17px;
	left: 50%;
	transform: translateX(-50%);
}

.PlanificationCalendar-day-not-empty-add-button {
	background-color: #0096FF !important;
	border-radius: 8px !important;
	padding: 4px !important;
}

.PlanificationCalendar-day-emptymessage-box {
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.PlanificationCalendar-day-emptymessage-box:hover {
	display: flex;
}

.PlanificationCalendar-day-emptymessage-box .PlanificationCalendar-day-emptymessage {
	display: none;
	background-color: #0096FF;
	color: #fff;
	justify-content: center;
	align-items: center;
}

.PlanificationCalendar-day-emptymessage-box:hover .PlanificationCalendar-day-emptymessage {
	display: flex;
}

.PlanificationCalendar-day-container .PlanificationCalendar-day-not-empty-add-container {
	display: none;
}

.PlanificationCalendar-day-container:hover .PlanificationCalendar-day-not-empty-add-container {
	display: flex;
}

.PlanificationCalendar-day-emptymessage:hover {
	background-color: #aaa !important;
}

@media screen and (max-width: 1100px) {
	.PlanificationCalendar-day-container,
	.PlanificationCalendar-day-container.PlanificationCalendar-day-container-current {
		background-color: #f2f2f2;
	}

	.PlanificationCalendar-day-container.PlanificationCalendar-day-container-current {
		border: 2px solid #444;
		box-shadow: 0 1px 2px rgba(0,0,0, .2);
	}

	.PlanificationCalendar-day-body {
		min-height: 6rem;
	}

	.PlanificationCalendar-day-emptymessage-box .PlanificationCalendar-day-emptymessage {
		display: flex;
	}

	.PlanificationCalendar-day-container .PlanificationCalendar-day-not-empty-add-container {
		display: flex;
	}
}

.AccountPostingSettings-container {
	display: flex;
	flex-direction: column;
	gap: 45px;
}

.AccountPostingSettings-sub-container {
	max-width: 500px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
}

.AccountPostingSettings-preferred-platform-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;
	gap: 20px;
	justify-content: center;
}

.AccountPostingSettings-preferred-platform-item {
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 10px;
	padding: 18px 30px;
	box-shadow: 0 8px 0 -5px rgba(0,0,50, .2);
	background: linear-gradient(0deg, #bbb, 4%, #fff);
	border-radius: 16px;
	transition: .12s ease-in-out;
}

.AccountPostingSettings-preferred-platform-item.AccountPostingSettings-preferred-platform-item-active {
	background: linear-gradient(0deg, #c8c8ff, 4%, #fff);
	box-shadow: 0 10px 0 -5px rgba(120,120,220, .5);
	cursor: pointer;
}

.AccountPostingSettings-preferred-platform-item:hover {
	cursor: pointer;
	box-shadow: 0 8px 0 -5px rgba(120,120,220, .8);
}

.AccountPostingSettings-posts-per-week-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;
	gap: 20px;
	justify-content: center;
}

@media screen and (max-width: 768px) {
	.AccountPostingSettings-sub-container {
		min-width: 100%;
	}

	.AccountPostingSettings-preferred-platform-item {
		width: 80%;
	}
}

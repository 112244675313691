.AccountSettingsPage-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: fit-content;
	margin: 0 auto;
}

.AccountSettingsPage-account-container {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.AccountSettingsPage-sub-container {
	padding: 20px;
	border-radius: 16px;
	border: 1px solid #eee;
	background-color: #fff;
	border: 1px solid #ddd;
	box-shadow: 0 10px 18px -15px rgba(80,80,80, .1);
	/* box-shadow: 6px 7px 0 rgb(130,180,130); */
	max-width: 660px;
}

.AccountSettingsPage-sub-container.AccountSettingsPage-sub-container-red {
	border: 1px solid rgba(255,80,80, .4);
	box-shadow: 0 15px 0 -10px rgb(255,80,80);
}

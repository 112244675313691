.BlogPage-container {
	padding: 90px 20px 40px 20px;
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	width: fit-content;
	gap: 10px;
}

.BlogPage-content-container {
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
	background-color: rgb(247,247,249);
	border-radius: 15px;
}

.BlogPage-content-container.BlogPage-relatated-articles-container {
	max-width: 400px;
	height: fit-content;
	background-color: #222;
}

.BlogPage-content-container.BlogPage-relatated-articles-container .BlogPage-content {
	padding: 30px 25px;
}

.BlogPage-content {
	position: relative;
	padding: 40px 50px;
}

.BlogPage-share-buttons-container {
	position: absolute;
	display: flex;
	flex-direction: row;
	top: 10px;
	right: 20px;
}

.BlogPage-title {
	font-family: 'IBM Plex Sans', 'Source Serif Pro', 'Source' !important;
	color: #000 !important;
	font-size: 36px !important;
	font-weight: 700 !important;
	margin-bottom: 25px !important;
}

.BlogPage-content--title,
.BlogPage-content--body,
.BlogPage-content--image,
.BlogPage-content--video {
	margin-bottom: 30px;
}

.BlogPage-content--title {
	font-family: 'Source Serif Pro', 'Source' !important;
	color: #111 !important;
	font-size: 26px !important;
	font-weight: 650 !important;
	margin-bottom: 0;
}

.BlogPage-content--body {
	font-family: 'Source Serif Pro', 'Source' !important;
	color: #222 !important;
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 1.45;
}

.BlogPage-hero-img,
.BlogPage-content--image,
.BlogPage-content--video {
	display: block;
	width: 75%;
	margin: 0 auto 40px auto;
	border-radius: 10px;
	box-shadow: 0 1px 5px rgba(0,0,0, .2);
}

.BlogPage-content-author {
	color: #222 !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	margin-top: 60px;
}

.BlogPage-related-posts {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.BlogPage-related-posts-title {
	font-family: 'IBM Plex Sans', 'Source Serif Pro', 'Source' !important;
	color: #fff !important;
	font-size: 23px !important;
	font-weight: 650 !important;
	margin-bottom: 25px !important;
}

.BlogPage-post-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 8px 15px;
	border: 1px solid transparent;
	border-radius: 8px;
	border: 1px solid #444;
	box-shadow: 0 2px 4px -2px rgba(160,160,160, .1);
	gap: 12px;
	transition: .2s ease;
}

.BlogPage-post-container:hover {
	border: 1px solid #888;
}

.BlogPage-post-text-container {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.BlogPage-post-title {
	font-family: 'IBM Plex Sans', 'Source Serif Pro', 'Source' !important;
	color: #eee !important;
	font-size: 12px !important;
	font-weight: 600 !important;
}

.BlogPage-post-author {
	color: #aaa !important;
	font-size: 11px !important;
	font-weight: 500 !important;
}

.BlogPage-post-img {
	width: 60px;
	height: 60px;
	object-fit: cover;
	border-radius: 8px;
}

@media screen and (max-width: 1200px) {
	.BlogPage-container {
		flex-direction: column;
	}

	.BlogPage-content-container.BlogPage-relatated-articles-container {
		max-width: 100%;
	}

	.BlogPage-title {
		font-size: 30px !important;
	}

	.BlogPage-content--title {
		font-size: 21px !important;
	}

	.BlogPage-content--body {
		font-size: 15px !important;
	}

	.BlogPage-content-author {
		font-size: 12px !important;
	}

	.BlogPage-post-title {
		font-size: 15px !important;
	}

	.BlogPage-post-img {
		width: 90px;
		height: 90px;
	}
}

@media screen and (max-width: 680px) {
	.BlogPage-container {
		padding: 75px 10px 40px 10px;
	}

	.BlogPage-content {
		padding: 40px 20px;
	}

	.BlogPage-hero-img,
	.BlogPage-content--image,
	.BlogPage-content--video {
		width: 90%;
	}


	.BlogPage-post-title {
		font-size: 12px !important;
	}

	.BlogPage-post-img {
		width: 65px;
		height: 65px;
	}
}

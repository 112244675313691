.FeaturesSection-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 55px 20px;
	justify-content: center;
	gap: 80px;
	background-color: #eee;
}

.FeaturesSection-feature-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	max-width: 200px;
}

.FeaturesSection-feature-icon {
	width: 65px;
	height: 65px;
}

@media screen and (max-width: 600px) {
	.FeaturesSection-container {
		flex-direction: column;
	}

	.FeaturesSection-feature-container {
		margin: 0 auto;
	}
}

.AccountAdminSettings-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
}

.AccountAdminSettings-infos-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 50px;
}

.AccountAdminSettings-infos-avatar-container {
	display: flex;

	/* TODO: to remove when the avatar is implemented */
	display: none;
	flex-direction: column;
	align-items: center;
	gap: 10px;
}

.AccountAdminSettings-infos-username-container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	/* TODO: to remove when the avatar is implemented */
	align-items: flex-start;
	gap: 15px;
}

.AccountAdminSettings-infos-avatar {
	width: 76px !important;
	height: 76px !important;
	border: 4px solid #444;
}

.AccountAdminSettings-infos-avatar-upload-button {
	box-shadow: 4px 4px 0 #0096FF !important;
}

.AccountAdminSettings-infos-avatar-upload-button:hover {
	box-shadow: 0 0 0 #fff !important;
}


@media screen and (max-width: 768px) {
	.AccountAdminSettings-infos-container {
		flex-direction: column;
	}
}

.PageHeader-container {
	position: relative;
	max-width: 1275px;
	margin: 0 auto;
	width: 100%;
	margin-bottom: 25px;
}

.PageHeader-container.PageHeader-container-full-width {
	max-width: 100%;
}

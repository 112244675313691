.ThumbnailSelector-container {
	position: relative;
	display: flex;
	flex-direction: row;
	border-radius: 12px;
	box-shadow: 0 5px 18px -8px rgba(0,0,0, .4);
	width: 100%;
	height: 60px;
	user-select: none;
	cursor: grab;
}

.ThumbnailSelector-skeleton {
	position: relative;
	width: 100% !important;
	height: 100% !important;
	border-radius: 12px;
}

.ThumbnailSelector-mini-video {
	position: absolute;
	top: 0px;
	height: 70px;
	width: 90px;
	object-fit: cover;
	border-radius: 15px;
	transform: translateY(-10px);
	border: 5px solid #ff0050;
	z-index: 1000;
}

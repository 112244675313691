.LegalTermsPage-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px 5%;
	gap: 40px;
}

.LegalTermsPage-brand-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.LegalTermsPage-text-container {
	max-width: 800px;
	text-align: justify;
}

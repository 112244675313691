.PlanificationModal-container,
.PlanificationModal-container-dark {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1200px;
	background: linear-gradient(200deg, #eee, 75%, #ddd);
	border-radius: 23px;
}

.PlanificationModal-container.PlanificationModal-container-dark {
	background: linear-gradient(200deg, #181818, 75%, #323232);
}

/* Common Styling */
.Planification-content-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	gap: 35px;
	margin-top: 30px;
}

.Planification-avatar-container {
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	background: linear-gradient(0deg, #e5e5e5, #f8f8f8);
	padding: 6px 10px;
	border-radius: 20px;
	box-shadow: 0 5px 12px -8px rgba(0,0,0, .12);
	gap: 10px;
}

.Planification-avatar-name {
	font-weight: 800 !important;
	color: #333 !important;

	/* max chars */
	width: 160px;
	white-space: nowrap;

	/* text overflow */
	overflow: hidden;
	text-overflow: ellipsis;
}

.Planification-fields-container {
	position: relative;
	display: flex;
	min-width: 300px;
	max-width: 450px;
	max-height: 600px;
	overflow-y: auto;
	flex-direction: column;
	padding: 18px 18px 0 18px;
	border-radius: 15px;
	box-shadow: 50px 60px 50px rgba(0,0,0, .25);
	background-color: #fcfcfc;
	gap: 18px;
}

/* hide scroll bar for .Planification-fields-container  */
.Planification-fields-container::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}

.Planification-field-item-container {
	display: flex;
	flex-direction: column;
	gap: 3px;
}

.Planification-field-item-title-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
}


.Planification-field-item-title {
	color: #444 !important;
	font-weight: 600 !important;
}

.Planification-media-type-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.Planification-media-type-icon {
	/* background-color: #e5e5e5;
	background-color: #e5e5e5; */
	background: linear-gradient(0deg, #e5e5e5, #f8f8f8);
	/* background-color: #222; */
	padding: 4px 5px;
	border-radius: 10px;
	box-shadow: 0 1px 3px -2px rgba(0,0,0, .15);
}

.Planification-media-type-text {
	color: #888 !important;
	font-weight: 700 !important;
}

.Planification-is-schedulable-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 12px;
	border-radius: 13px;
	box-shadow: 0 3px 15px rgba(0,0,0, .25);
	background-color: red;
	gap: 10px;
}

.Planification-is-schedulable-text {
	color: #eee !important;
	font-weight: 700 !important;
}

.Planification-file-selector-button {
	font-weight: 700 !important;
	background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
	animation: Planification-file-selector-button-pulse 2s infinite;
}

.Planification-thumb-selector-button {
	font-weight: 700 !important;
}

.Planification-action-buttons-container {
	position: sticky;
	flex-direction: row;
	bottom: 0;
	display: flex;
	padding: 12px 0;
	background-color: #fcfcfc;
	backdrop-filter: blur(6px) saturate(180%);
	-webkit-backdrop-filter: blur(6px) saturate(180%);
	gap: 10px;
	z-index: 1;
}

.Planification-infos-island {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 15px;
	padding: 9px 12px;
	background-color: #222;
	border-radius: 21px;
	box-shadow: 0 18px 28px -15px rgba(0,0,0, .6);
}


@media screen and (max-width: 768px) {
	.Planification-content-container {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		gap: 60px;
	}
}


@keyframes Planification-file-selector-button-pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 105, 135, 1);
	}
	70% {
		box-shadow: 0 0 0 9px rgba(255, 105, 135, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(255, 105, 135, 0);
	}
}

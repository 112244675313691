.AccountUpdatePassword-container {
	display: flex;
	flex-direction: column;
	width: fit-content;
	gap: 18px;
	margin: 0 auto;
}

.AccountUpdatePassword-fields-container {
	display: flex;
	flex-direction: row;
	width: fit-content;
	gap: 8px;
}


@media screen and (max-width: 500px) {
	.AccountUpdatePassword-fields-container {
		flex-direction: column;
	}
}

.ChangelogPage-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 5%;
	gap: 50px;
}

.ChangelogPage-brand-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
}

.ChangelogPage-changelog-container {
	display: flex;
	flex-direction: column;
	gap: 60px;
}

.ChangelogPage-changelog-item-container {
	display: flex;
	flex-direction: column;
	max-width: 800px;
	width: 100%;
	text-align: justify;
	gap: 15px;
}

.ChangelogPage-changelog-changes-container {
	padding: 0px;
}

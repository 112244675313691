.AccountsLoginPage-container {
	max-width: 800px;
	margin: 0 auto;
}

.Accordion-instagram {
	/* background-color: #f0f0f3 !important; */
}

.Accordion-tiktok {
	background-color: #000 !important;
}

.Accordion-title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
}

.Accordion-title-icon-dark { color: black; }
.Accordion-title-icon-light { color: white; }

.AccountsLoginPage-disclaimer-container {
	margin-top: 35px;
	padding: 35px 30px 20px 30px;
	background-color: #8CA87E;
	border-radius: 18px;
	box-shadow: 0 15px 20px -15px rgba(0,0,0, .2);
}

.SocialAccountSelection-container {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	overflow-x: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
	width: fit-content;
	max-width: 505px;
	/* width: 100%; */
	/* max-width: 100%; */
	gap: 25px;
	padding: 6px;

	/* background-color: #FF7F507c;
	border-radius: 22px; */
	padding: 12px 18px;
}

.SocialAccountSelection-container::-webkit-scrollbar {
	display: none;
}

.SocialAccountSelection-item-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
}

.SocialAccountSelection-item-container:hover {
	cursor: pointer;
}

.SocialAccountSelection-badge-container {
	border-radius: 50%;
	padding: 5px;
	box-shadow: 0 6px 10px -3px rgba(0,0,0, .3);
	transition: .12s ease-in;
}

.SocialAccountSelection-item-container-connect .SocialAccountSelection-badge-container {
	background-color: #0096FF;
	box-shadow: none;
}

.SocialAccountSelection-item-container-connect:hover .SocialAccountSelection-badge-container {
	background-color: #555;
}

.SocialAccountSelection-item-container .SocialAccountSelection-item-username {
	width: 65px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;
	font-weight: 700;
	border-radius: 9px;
	padding: 4px 8px;
	background-color: #fff;
	box-shadow: 0 6px 8px -8px rgba(0,0,0, .4);
	transition: .3s ease-in-out;
}

.SocialAccountSelection-item-container:hover .SocialAccountSelection-item-username {
	background-color: #0096FF;
	color: #fff !important;
}

.SocialAccountSelection-item-container-connect:hover .SocialAccountSelection-item-username {
	/* background-color: #ddd; */
	color: #aaa !important;
	background-color: transparent;
}

.SocialAccountSelection-item-container-connect .SocialAccountSelection-item-username {
	background-color: transparent;
	box-shadow: none;
}

.SocialAccountSelection-item-container:active .SocialAccountSelection-badge-container {
	background: #888;
}

.SocialAccountSelection-item-container.SocialAccountSelection-item-container-loading .SocialAccountSelection-badge-container {
	background: #c5c5c5;
}

.SocialAccountSelection-item-container.SocialAccountSelection-item-container-loading:hover .SocialAccountSelection-item-username {
	background-color: unset;
}

.SocialAccountSelection-badge {
	padding: 3px;
	border-radius: 50%;
	width: 35px !important;
	height: 35px !important;
	background-color: #333;
}

.SocialAccountSelection-avatar {
	width: 65px !important;
	height: 65px !important;
	border-radius: 50%;
	background-color: #fff;
}

.SocialAccountSelection-item-container-connect .SocialAccountSelection-avatar {
	background-color: transparent;
	width: 25px !important;
	height: 25px !important;
}


@media screen and (max-width: 768px) {
	.SocialAccountSelection-container {
		max-width: 325px;
		/* width: 100%;
		max-width: 100%; */
	}
}

@media screen and (max-width: 425px) {
	.SocialAccountSelection-container {
		max-width: 280px;
		/* width: 100%;
		max-width: 100%; */
	}
}
.SecuritySection-container {
	/* height: 100vh; */
	padding: 40px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.SecuritySection-title {
	color: #000 !important;
	text-align: center;
}

.SecuritySection-image-container {
	position: relative;
	margin-top: 40px;
}

.SecuritySection-img {
	position: relative;
	display: block;
	width: 100%;
}

.SecuritySection-lock-container {
	position: absolute;
	display: flex;
	width: 100%;
	justify-content: center;
	top: 50%;
}

.SecuritySection-lock {
	box-sizing: border-box;
	width: 34px;
	height: 30px;
	border: 2.5px solid #fff;
	border-radius: 4px;
	position: relative;
	cursor: pointer;
	-webkit-transition: all .2s ease-in-out .5s;
	transition: all 0.42s ease-in-out .5s;
	transform: scale(2.6);
}

.SecuritySection-lock:after {
	content: "";
	display: block;
	background: #fff;
	width: 4px;
	height: 6px;
	border-radius: 1px;
	position: absolute;
	top: 50%;
	left: 50%;
	box-shadow: 1.5px 1px 0 rgb(120,120,255);
	margin: -3.5px 0 0 -2px;
	-webkit-transition: all .2s ease-in-out .5s;
	transition: all 0.42s ease-in-out .5s;
}

.SecuritySection-lock:before {
	content: "";
	display: block;
	width: 16px;
	height: 14px;
	bottom: 100%;
	position: absolute;
	left: 50%;
	margin-left: -11px;
	border: 2.5px solid #fff;
	border-top-right-radius: 50px;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-bottom: 0;
	-webkit-transition: all .2s ease-in-out .5s;
	transition: all 0.42s ease-in-out .5s;
}

.SecuritySection-lock:hover:before {
	height: 15px;
}

.SecuritySection-lock.SecuritySection-unlocked {
	transform: rotate(10deg) scale(2.6);
}

.SecuritySection-lock.SecuritySection-unlocked:before {
	bottom: 121%;
	left: 31%;
	margin-left: -11.5px;
	transform: rotate(-45deg);
}

.SecuritySection-lock.SecuritySection-unlocked,
.SecuritySection-lock.SecuritySection-unlocked:before {
	border-color: #ff6060;
}

.SecuritySection-lock.SecuritySection-unlocked:after {
	background: #ff6060;
}

.SecuritySection-lock.SecuritySection-unlocked:hover {
	transform: rotate(3deg) scale(2.6);
}

.SecuritySection-lock.SecuritySection-unlocked:hover:before {
	height: 15px;
	left: 40%;
	bottom: 118%;
	transform: rotate(-30deg);
}


@media screen and (max-width: 768px) {
	.SecuritySection-lock {
		transform: scale(1.2);
	}
	.SecuritySection-lock.SecuritySection-unlocked {
		transform: rotate(10deg) scale(1.2);
	}
	.SecuritySection-lock.SecuritySection-unlocked:hover {
		transform: rotate(3deg) scale(1.2);
	}
}

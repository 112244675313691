.PricingPage-hero-section-container {
	background: linear-gradient(130deg, #D8EAE4 0%, #FFF 20%, #FAFDFE 80%, #F6DFE9 90%, #F6E2D2 100%);
}

.PricingPage-hero-section {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	max-width: 1200px;
	margin: 0 auto;
	padding: 280px 30px 200px 30px;
	gap: 50px;
}

.PricingPage-hero-section-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
	text-align: center;
	width: 50%;
}

.PricingPage-hero-glass-fx {
	position: absolute;
	background: rgba(80,80,200, 0.12);
	transform: rotate(-30deg) translate(0%, 20%);
	border-radius: 16px;
	width: 500px;
	height: 120px;
	box-shadow: 0 3px 15px rgba(0,0,0, .1);
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
	z-index: 2;
	/* animation: PricingPage-hero-glass-fx-animation 12s ease-in-out infinite; */
	animation: PricingPage-hero-glass-fx-animation 1.5s ease-in-out 1;
}

.PricingPage-price-container {
	background-color: #f5f5f5;
	padding: 70px 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	gap: 30px;
}

.PricingPage-features-container {
	padding: 60px 0 0 0;
}

.PricingPage-features-title-container {
	text-align: center;
}

.PricingPage-features {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* text-align: center; */
	max-width: 1200px;
	margin: 0 auto;
	padding: 70px 15px;
	gap: 30px;
}

.PricingPage-features-box-container {
	display: grid; 
	grid-template-columns: repeat(2, 1fr); 
	grid-template-rows: repeat(2, 1fr); 
	grid-column-gap: 0px;
	grid-row-gap: 0px; 
	gap: 12px;
}

.PricingPage-features-item-container {
	width: 220px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	gap: 30px;
	background-color: rgb(247,247,249);
	box-shadow: 0 10px 5px -10px rgba(0,0,0, .15);
	border-radius: 8px;
	padding: 25px;
}

.PricingPage-features-item-text-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	justify-content: center;
	gap: 8px;
}


@media screen and (max-width: 1160px) {
	.PricingPage-features {
		flex-direction: column;
	}

	.PricingPage-features-item-container {
		width: 300px;
		justify-content: center;
		margin: 0 auto;
	}
}

@media screen and (max-width: 768px) {
	.PricingPage-hero-section {
		flex-direction: column-reverse;
		padding: 160px 22px 70px 22px;
		gap: 120px;
	}

	.PricingPage-hero-section-content {
		width: 100%;
	}

	.PricingPage-hero-glass-fx {
		transform: rotate(-25deg) translate(0%, 20%);
		backdrop-filter: blur(2px);
		-webkit-backdrop-filter: blur(2px);
		width: 280px;
		height: 50px;
	}

	.PricingPage-features-item-container {
		width: 220px;
		padding: 15px;
	}
}

@media screen and (max-width: 650px) {
	.PricingPage-features-item-container {
		width: 180px;
	}

	.PricingPage-features-item-text-container {
		flex-direction: column;
	}
}

@media screen and (max-width: 460px) {
	.PricingPage-features-item-container {		
		width: 110px;
	}
}


@keyframes PricingPage-hero-glass-fx-animation {
	0% {
		transform: rotate(0deg) translate(0%, 0%);
	}
	100% {
		transform: rotate(-30deg) translate(0%, 20%);
	}
}

.SocialAccountLogin-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.SocialAccountLogin-connection-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.SocialAccountLogin-items-container {
	display: flex;
	flex-direction: column;
	gap: 25px;
}

.SocialAccountLogin-item-container {
	background-color: #f5f5f5;
	border: 2px solid #ddd;
	/* background: linear-gradient(140deg, #FFF 70%, #F6E2D2 100%); */
	border: 1px solid #ddd;
	border-radius: 15px;
	padding: 10px 15px;
	box-shadow: 0 15px 12px rgba(120,120,120, .08);
}

.SocialAccountLogin-container-dark .SocialAccountLogin-item-container {
	border: 2px solid #333;
	box-shadow: 0 15px 20px rgba(255,255,255, .12);
}

.SocialAccountLogin-profile-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
	gap: 20px;
}

.SocialAccountLogin-profile-picture {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 3px solid #555;
}

.SocialAccountLogin-profile-infos {
	display: flex;
	flex-direction: column;
}

.SocialAccountLogin-profile-buttons {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.SocialAccountLogin-accounts-list-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	/* margin-top: 20px; */
	justify-content: space-between;
	gap: 15px;
}

.SocialAccountLogin-account-container {
	flex: 1 45%;
	display: flex;
	width: fit-content;
	min-width: 380px;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	padding: 5px;
	margin: 8px 0;
	border-radius: 10px;
	border: 2px solid #f5f5f5;
	gap: 25px;
	box-shadow: 5px -4px 0 #c8c8c8;
	transition-property: box-shadow, background-color;
	transition: .15s ease;
}

.SocialAccountLogin-account-container.SocialAccountLogin-account-selected {
	background-color: #fff;
	box-shadow: 5px -4px 0 rgb(234,51,35);
}

.SocialAccountLogin-container-dark .SocialAccountLogin-account-container.SocialAccountLogin-account-selected {
	box-shadow: 5px -4px 0 #808080;
}

.SocialAccountLogin-account-profile-picture {
	width: 55px !important;
	height: 55px !important;
	border-radius: 50%;
	box-shadow: -10px 15px 20px rgba(0,0,0, .15);
}

.SocialAccountLogin-account-profile-infos-container {
	display: flex;
	flex-direction: column;
	gap: 2px;
}

.SocialAccountLogin-account-profile-infos {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.SocialAccountLogin-login-button {
	color: #fff;
	background-color: #3b5998 !important;
	border: none;
	border-radius: 4px;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
}

@media screen and (max-width: 768px) {
	.SocialAccountLogin-profile-header {
		display: flex;
		flex-direction: column;
		margin-bottom: 35px;
		gap: 8px;
	}

	.SocialAccountLogin-account-container {
		min-width: 240px;
		gap: 18px;
	}

	.SocialAccountLogin-account-profile-infos-container {
		gap: 12px;
	}

	.SocialAccountLogin-account-profile-infos {
		flex-direction: column;
		align-items: flex-start;
		gap: 5px;
	}
}

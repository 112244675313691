.BlogSection-container {
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 40px 0;
	background-color: #f5f5f5;
}

.BlogSection-title {
	color: #000 !important;
	text-align: center;
}

.BlogSection-articles-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
}

.BlogSection-single-article-container {
	display: flex;
	flex-direction: column;
	width: 280px;
	border-radius: 15px;
	box-shadow: 0 22px 25px -10px rgba(0,0,0, .1);
	border: 1px solid #e5e5e5;
	transition: all .22s ease-in-out;
}

.BlogSection-single-article-container:hover {
	cursor: pointer;
	box-shadow: 0 25px 20px -10px rgba(0,0,0, .15);
}

.BlogSection-single-article-container:active {
	box-shadow: 5px 12px 22px rgba(120,120,220, .5);
}

.BlogSection-single-article-img-container {
	position: relative;
}

.BlogSection-single-article-img {
	width: 100%;
	height: 140px;
	object-fit: cover;
	border-radius: 12px 12px 0 0;
}

.BlogSection-single-article-chip {
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: #eee !important;
}

.BlogSection-single-article-text-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 80px;
	gap: 10px;
	padding: 10px 20px;
}

.BlogSection-single-article-text {
	color: #000 !important;
	font-size: 13px !important;
	font-weight: 500 !important;
}

.BlogSection-single-article-date {
	color: #000 !important;
	font-size: 11px !important;
	font-weight: 400 !important;
}

@media screen and (max-width: 768px) {
	.BlogSection-single-article-container {
		width: 75vw;
	}

	.BlogSection-single-article-text-container {
		min-height: 60px;
	}

	.BlogSection-single-article-text {
		font-size: 12px !important;
	}

	.BlogSection-single-article-date {
		font-size: 10px !important;
	}
}

.ContentCarrousel-inner-container {
	width: 100%;
	height: 500px;
	object-fit: contain;
}

.ContentCarrousel-content {
	width: 100%;
	height: 500px;
	object-fit: contain;
	transition: .2s ease-in-out;
}

.ContentCarrousel-arrow-container {
	opacity: .3;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 50%;
	z-index: 1;
	cursor: pointer;
	transition: .2s ease-in-out opacity;
}

.ContentCarrousel-arrow-container.ContentCarrousel-arrow-prev {
	left: 0;
}

.ContentCarrousel-arrow-container.ContentCarrousel-arrow-next {
	right: 0;
}

.ContentCarrousel-arrow {
	color: #ddd;
	display: block;
	font-size: 60px;
}

.ContentCarrousel-arrow-container:hover,
.ContentCarrousel-inner-container:hover ~ .ContentCarrousel-arrow-container {
	opacity: 1;
}

.DuplicateEventButton-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
}

.DuplicateEventButton-account-item-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 15px;
}

.DuplicateEventButton-account-item-image {
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.DuplicateEventButton-account-item-icon {
	width: 25px;
	height: 25px;
}

.PlanificationCalendar-navigation-container {
	height: 3rem;
	width: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	padding: 1rem 1rem;
}

.PlanificationCalendar-navigation-buttons {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.PlanificationCalendar-navigation-main-button {
	background-color: #000 !important;
	width: 42px;
	height: 42px;
	border-radius: 50%;
	cursor: pointer;
}

.PlanificationCalendar-navigation-main-button:hover {
	background-color: #888 !important;
}

.PlanificationCalendar-navigation-text {
	width: fit-content;
	font-size: 1.9rem;
	font-weight: 700;
	color: #222;
	text-align: center;
	text-transform: capitalize;
}


@media screen and (max-width: 768px) {
	.PlanificationCalendar-navigation-text {
		font-size: 1.1rem;
	}
	.PlanificationCalendar-navigation-buttons {
		gap: 8px;
	}
	.PlanificationCalendar-navigation-main-button {
		width: 34px;
		height: 34px;
	}
}